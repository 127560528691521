import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SwiperOptions } from 'swiper';
import { ImageList } from '../../../models/Images';
import { ImageModalComponent } from '../../image-modal/image-modal.component';
@Component({
  selector: 'app-green-images',
  templateUrl: './green-images.component.html',
  styleUrls: ['./green-images.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GreenImagesComponent implements OnInit {
  swiperParent: any;
  holeNumber: number;
  @Input() holeGreenImages: Array<ImageList>;
  @Input() type: String;


  config: SwiperOptions = {
    slidesPerView: 1.5,
    spaceBetween: 20,
    centeredSlides: true,

  };
  constructor(private modalCtrl: ModalController) { }
  ngOnInit(): void {
  }
  onSwiper(swiper) {
    this.swiperParent = swiper;
  }
  async openPreview(img) {
    const modal = await this.modalCtrl.create({
      component: ImageModalComponent,
      componentProps: {
        imgGreens: this.holeGreenImages,
        imgGreenSelected: img
      },
      cssClass: 'transparent-modal'
    });
    modal.present();
  }

}
