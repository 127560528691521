import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/Preferences';
import { AppService } from '../state/app/app.service';

@Injectable({ 
  providedIn: 'root',
})
export class UtilsService {
  token: string = null;
  constructor(private appService: AppService) { }
  async getApiHeaders() {
    if (this.token == null) {
      const token = await this.getToken();
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      });
      return headers;
    } else {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      });
      return headers;
    }
  }
  // JSON "get" example
  async getToken() {
    const val = this.token == null ? await this.appService.getCapacitorStorage('token') : false;
    if (val) {
      this.token = val;
      return val;
    }
    return val;

  }
  getApiNoAuthorizationHeaders() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }



 setPreference = async (val) => {
    await Preferences.set({
      key: 'prefToken',
      value: val,
    });
  };
  
 getPreference = async (val) => {
    const { value } = await Preferences.get({ key: val });

    return value;
  };
  
 removePreference = async (val) => {
    await Preferences.remove({ key: val});
    return true;
  };



}
