import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-slider-web',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
})
export class ImageSliderComponent implements OnInit {
  @Input() details: any = [];
  slidesOptions: any = {
    autoplay: true,
    paginationShow: true,
    initialSlide: 0,
    speed: 3200,
    zoom: {
      maxRatio: 1,
    },
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    }
  }
  constructor() { }

  ngOnInit() {
    this.details = [
      { source: 'https://clubmobile.blob.core.windows.net/images/course-train.jpg' },


    ];
  }

}
