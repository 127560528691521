import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-header-web',
  templateUrl: './header-web.component.html',
  styleUrls: ['./header-web.component.scss'],
})
export class HeaderWebComponent implements OnInit {
  @Input() title: string;
  @Input() showMenu: boolean = true;
  @Input() isWeb: boolean = true;
  isMobile = false;
  constructor(private platform: Platform) {

  }

  ngOnInit() {
    this.platform.ready().then(() => {
      if (this.platform.is('android')) {
        this.isMobile = true;
      } else if (this.platform.is('ios')) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }
}
