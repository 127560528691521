import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {
  constructor(private http: HttpClient) { 
    this.getWeather();

  }

  public async getWeather(){
    const api = 'https://api.weather.gov/gridpoints/CAE/78,73/forecast/hourly'
    const data = await this.http.get(api, {}).toPromise();
    return data;
  }
  public async getRadar(){
    const api = 'https://api.weather.gov/gridpoints/CAE/78,73/forecast/hourly'
    const data = await this.http.get(api, {}).toPromise();
    return data;
  }
}
