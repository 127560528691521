<ion-app>
    <!-- when=false means that the split pane will never expand automatically -->
    <!-- For more info check https://ionicframework.com/docs/api/split-pane -->
    <ion-split-pane contentId="menu-content" when="false">
        <ion-menu contentId="menu-content" class="app-sidemenu">
            <ion-header>
                <ion-toolbar>
                    <ion-row class="user-details-wrapper">
                        <ion-col size="4">
                            <app-aspect-ratio [ratio]="{w: 1, h: 1}">
                                <app-image-shell class="user-avatar" animation="spinner"
                                    [src]="'https://clubmobile.blob.core.windows.net/images/icon-transparent.png'"></app-image-shell>
                            </app-aspect-ratio>
                        </ion-col>
                        <ion-col class="user-info-wrapper" size="8">
                            <h3 class="user-name">Camden Country Club</h3>
                            <h5 class="user-handle">1903</h5>
                        </ion-col>
                    </ion-row>
                </ion-toolbar>
            </ion-header>
            <ion-content class="app-content">
                <ion-list *ngIf="isWebMobile && !getAppState()">
                    <ion-list-header>
                        <ion-label>Menu</ion-label>
                    </ion-list-header>
                    <ion-menu-toggle autoHide="false" *ngFor="let p of appWebMobilePages; let i = index">
                        <ion-item [routerLink]="p.url">
                            <ion-icon slot="start" [name]="p.ionicIcon? p.ionicIcon: ''"
                                [src]="p.customIcon? p.customIcon: ''"></ion-icon>
                            <ion-label>
                                {{p.title}}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>
                </ion-list>
                <div *ngIf="!isWebMobile || getAppState()">

                    <ion-list>
                        <ion-menu-toggle autoHide="false" *ngFor="let p of appHomePages; let i = index">
                            <ion-item [routerLink]="p.url">
                                <ion-icon slot="start" [name]="p.ionicIcon? p.ionicIcon: ''"
                                    [src]="p.customIcon? p.customIcon: ''"></ion-icon>
                                <ion-label>
                                    {{p.title}}
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>
                    </ion-list>
                    <ion-list>
                        <ion-list-header>
                            <ion-label>Golf</ion-label>
                        </ion-list-header>
                        <ion-menu-toggle autoHide="false" *ngFor="let p of appGolfPages; let i = index">
                            <ion-item [routerLink]="p.url">
                                <ion-icon slot="start" [name]="p.ionicIcon? p.ionicIcon: ''"
                                    [src]="p.customIcon? p.customIcon: ''"></ion-icon>
                                <ion-label>
                                    {{p.title}}
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>
                    </ion-list>
                    <ion-list>
                        <ion-list-header>
                            <ion-label>Club</ion-label>
                        </ion-list-header>
                        <ion-menu-toggle autoHide="false" *ngFor="let p of appMembershipPages; let i = index">
                            <ion-item [routerLink]="p.url">
                                <ion-icon slot="start" [name]="p.ionicIcon? p.ionicIcon: ''"
                                    [src]="p.customIcon? p.customIcon: ''"></ion-icon>
                                <ion-label>
                                    {{p.title}}
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>
                    </ion-list>
                    <ion-list>
                        <ion-list-header>
                            <ion-label>Account</ion-label>
                        </ion-list-header>
                        <ion-menu-toggle autoHide="false" *ngFor="let p of appUserPages; let i = index">
                            <ion-item [routerLink]="p.url">
                                <ion-icon slot="start" [name]="p.ionicIcon? p.ionicIcon: ''"
                                    [src]="p.customIcon? p.customIcon: ''"></ion-icon>
                                <ion-label>
                                    {{p.title}}
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>
                    </ion-list>
                    <ion-list>

                        <ion-item (click)="signOut()">
                            <ion-icon slot="start" name="close-circle-outline"></ion-icon>
                            <ion-label>
                                Sign Out
                            </ion-label>
                        </ion-item>
                    </ion-list>
                </div>

            </ion-content>
        </ion-menu>
        <ion-router-outlet id="menu-content"></ion-router-outlet>
    </ion-split-pane>
</ion-app>