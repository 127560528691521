<!-- <ion-card class="card-container-background" style="height: 100vh;">
    <iframe
        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcamden1903%2F&tabs=timeline&width=1000&height=2000&small_header=true&adapt_container_width=false&hide_cover=false&show_facepile=false&appId"
        style="border:none;overflow:hidden;height: 100%;width: 100%;" scrolling="no" frameborder="0"
        allowfullscreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
</ion-card> -->
<ion-card class="card-container-background">
    <ion-col size="12">
        <ion-item-divider sticky="true">
            <ion-label>Facebook</ion-label>
        </ion-item-divider>
    </ion-col>
    <div class="facebook-iframe-container">
        <iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcamden1903%2F&tabs=timeline&width=320&height=700&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            width="320" height="700" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </div>
</ion-card>