<ion-row>
  <ion-col size="3" color="primary" class="footer-background footer-message user-info-wrapper static-call-footer p-2">
    <a href="tel:+8034323322">

      <ion-button shape="round" class="footer-button" *ngIf="flagCallProShop" color="success" type="submit" expand="block">
        <ion-icon name="call-outline"></ion-icon>
      </ion-button>
    </a>

  </ion-col>
  <ion-col size="9" color="primary" class="footer-background footer-message user-info-wrapper static-call-footer p-2">
    <div class="m-1" [ngClass]="{'footer-message-center': flagCallProShop}">
      {{message}}

    </div>
  </ion-col>
</ion-row>