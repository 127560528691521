import { Injectable } from '@angular/core';
import { AppService } from '../state/app/app.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Constants } from "../../models/_constants";
import { Subscription } from 'rxjs';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {
  api: string;
  postId: any;

  constructor(
    private http: HttpClient,
    public constants: Constants,
    private utilsService: UtilsService
  ) {
    this.api = this.constants.apiOneSignalNotifications;
  }

  public async getOneSignalNotifications() {
    const header = await this.utilsService.getApiHeaders();
    const data = await this.http.get<any>(this.api, { headers: header, }).toPromise();
    return data;
  }
}
