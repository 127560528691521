<ion-content>
  <ion-row c>
    <ion-col>
      <h5 class="mar-left-10">{{viewTitle}}</h5>

    </ion-col>

  </ion-row>
  <ion-grid>
    <!-- <ion-row>
      <ion-col size="12">
        <ion-buttons end>
          <ion-button (click)="today()" color="primary" [disabled]="isToday" size="small" shape="round">Today
          </ion-button>
          <ion-button (click)="changeMode('month')" color="primary" [disabled]="isToday" size="small" shape="round">M
          </ion-button>
          <ion-button (click)="changeMode('week')" color="primary" [disabled]="isToday" size="small" shape="round">W
          </ion-button>
          <ion-button (click)="changeMode('day')" color="primary" [disabled]="isToday" size="small" shape="round">
            D </ion-button>
          <ion-button (click)="loadEvents()" color="primary" [disabled]="isToday" size="small" shape="round">Load Events
          </ion-button>
        </ion-buttons>
      </ion-col>
    </ion-row> -->
    <ion-row>
      <ion-col size="12">

        <calendar [eventSource]="eventSource" [calendarMode]="calendar.mode" [currentDate]="calendar.currentDate"
          (onCurrentDateChanged)="onCurrentDateChanged($event)" (onEventSelected)="onEventSelected($event)"
          (onTitleChanged)="onViewTitleChanged($event)" (onTimeSelected)="onTimeSelected($event)"
          [step]="calendar.step">
        </calendar>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>