<ion-grid *ngIf="contacts.length > 0" class="w-100" class="contact-list">
  <ion-row>
    <ion-col sizeSm="6" sizeXs="12" *ngFor="let p of contacts; let i = index">
      <ion-item>
        <ion-label>
          <h2>{{p.name}}</h2>
          <p>{{p.description}}</p>
          <p> <a href="tel:{{p.phone}}" class="button button-positive">{{p.phone}}</a></p>
        </ion-label>
      </ion-item>
    </ion-col>
  </ion-row>
</ion-grid>