<!-- <ion-header>
  <ion-toolbar>
    <ion-buttons  *ngIf="showMenu" slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>{{title}}</ion-title>

  </ion-toolbar>
</ion-header> -->
<ion-header class="web-black header-web ion-no-border">
  <ion-toolbar class="web-black p-2">
    <ion-buttons *ngIf="isMobile" slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title class="titleHeader web-black p-2">
      <div class="header-web-title mar-left-20" *ngIf="!isMobile">{{title}}</div>
      <h2 *ngIf="isMobile"> {{title}}
      </h2>

    </ion-title>
    <ion-buttons [routerLink]="['/home']" *ngIf="isWeb  && !isMobile" slot="primary" class="header-web-button m-2">
      <ion-button>
        Home
      </ion-button>
    </ion-buttons>
    <ion-buttons [routerLink]="['/membership']" *ngIf="isWeb && !isMobile" slot="primary" class="header-web-button m-2">
      <ion-button>
        Membership
      </ion-button>
    </ion-buttons>
    <ion-buttons [routerLink]="['/course']" *ngIf="isWeb && !isMobile" slot="primary" class="header-web-button m-2">
      <ion-button>
        Golf
      </ion-button>
    </ion-buttons>
    <ion-buttons [routerLink]="['/pool']" *ngIf="isWeb && !isMobile" slot="primary" class="header-web-button m-2">
      <ion-button>
        Swimming
      </ion-button>
    </ion-buttons>
    <ion-buttons [routerLink]="['/tennis']" *ngIf="isWeb && !isMobile" slot="primary" class="header-web-button m-2">
      <ion-button>
        Tennis
      </ion-button>
    </ion-buttons>
    <ion-buttons [routerLink]="['/contact']" *ngIf="isWeb && !isMobile" slot="primary" class="header-web-button m-2">
      <ion-button>
        Contact
      </ion-button>
    </ion-buttons>
    <ion-buttons *ngIf="isMobile" slot="primary">
      <ion-button [routerLink]="['/app/login']" class="m-1 p-1 {{isMobile ? ' button-red' : ''}} ">
        Member Login
      </ion-button>
    </ion-buttons>
    <ion-buttons [attr.slot]="isMobile ? 'end': 'start'">
      <img style="margin:5px;background-color: white;border-radius: 30px;padding:5px 5px 5px 5px"
        src="https://clubmobile.blob.core.windows.net/images/icon-transparent.png" alt="your image"
        width="{{isMobile ? '30' : '60'}}" height="{{isMobile ? '30' : '60'}}">
    </ion-buttons>

  </ion-toolbar>
</ion-header>