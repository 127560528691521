<ion-row class="user-stats-section">
    <ion-col size="12">
        <ion-item-divider sticky="true">
            <ion-label>Membership</ion-label>
        </ion-item-divider>
    </ion-col>
    <ion-col class="user-stats-wrapper" size="4">
        <span class="stat-value">
            <h4 class="mar-no">405</h4>
        </span>
        <span class="stat-name">Total</span>
    </ion-col>
    <ion-col class="user-stats-wrapper" size="4">
        <span class="stat-value">
            <h4 class="mar-no">302</h4>
        </span>
        <span class="stat-name">Golfing</span>
    </ion-col>
    <ion-col class="user-stats-wrapper" size="4">
        <span class="stat-value">
            <h4 class="mar-no">103</h4>

        </span>
        <span class="stat-name">Sports & Social</span>
    </ion-col>
</ion-row>