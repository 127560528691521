import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController) { }
  async presentToastSuccess(message: string) {
    const toast = await this.toastController.create({
      header: message,
      position: 'bottom',
      duration: 4000,
      color: 'success'
    });
    toast.present();
  }
  async presentToastSingleError(message: string) {
    const toast = await this.toastController.create({
      header: message,
      position: 'bottom',
      duration: 4000,
      cssClass: 'toast-error'
    });
    toast.present();
  }
  async presentToastError(response: any) {
    if (response.error === undefined && typeof response === 'string') {
      this.presentToast(response, 'danger')
    }
    if (response.error === undefined && typeof response.title === 'string') {
      this.presentToast(response.title, 'danger')
    }
    for (let key in response.error.errors) {
      this.presentToast(response.error.errors[key], 'danger')
    }
  }
  private async presentToast(message: string, type: string) {
    const toast = await this.toastController.create({
      header: message,
      position: 'bottom',
      duration: 2000,
      color: type
    });
    toast.present();
  }

}
