<ion-content style="height: 100%;margin-bottom: 100px;">
  <ion-grid>
    <ion-row>
      <ion-col sizeXl="4" offsetXl="4">
        <h5 class="text-center">Current Calendar Events</h5>

        <ion-row class="mar-top-30">
          <ion-col size="10" offsetXs="1">
            <ion-button (click)="addEvent()" shape="round" color="{{showAddEvent ?  'secondary' : 'dark'}}"
              expand="full">
              <div *ngIf="showAddEvent">
                Cancel
              </div>
              <div *ngIf="!showAddEvent">
                Add Event
              </div>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-grid>

          <ion-row *ngIf="showAddEvent" class="mar-bottom-footer-100">
            <ion-card class="p-3">
              <ion-col>
                <form [formGroup]=" calendarForm" (ngSubmit)="createEvent()">
                  <hr>
                  <h6 class="text-center border-bottom">Pick Start Date & Time</h6>
                  <ion-datetime color="dark" displayFormat="YYYY-MM-DDTHH:mm:ss"
                    formControlName="startDate"></ion-datetime>

                  <h6 class="text-center border-bottom mar-top-30">Pick End Date & Time</h6>
                  <ion-datetime color="dark" displayFormat="YYYY-MM-DDTHH:mm:ss"
                    formControlName="endDate"></ion-datetime>

                  <h6 class="text-center border-bottom">Description</h6>

                  <ion-textarea style="height: 100px;" color="dark" type=" text" placeholder="Description goes here..."
                    formControlName="title"></ion-textarea>

                  <h6 class="text-center border-bottom">All Day Event</h6>
                  <ion-card-subtitle color="dark">Calendar will show 'all day' instead of time</ion-card-subtitle>
                  <ion-toggle class="mar-top-10 mar-bottom-footer-10" color="secondary" formControlName="allDay"
                    checked="false">Default
                    Toggle</ion-toggle>
                  <ion-button shape="round" class="signup-btn" type="submit" expand="block">Save
                  </ion-button>

                </form>

              </ion-col>
            </ion-card>

          </ion-row>

        </ion-grid>

        <ion-list style="max-height: 600px;overflow-y: scroll;" *ngIf="dataArray.length && !showAddEvent"
          class="backdrop-filter">
          <ion-row>
            <ion-col size="12" style="text-align: center;">

            </ion-col>
          </ion-row>
          <ion-item style="padding:0px !important;" *ngFor="let item of dataArray; let i = index">
            <ion-card class="w-100">
              <ion-row class="text-center">
                <ion-col size="12" class="text-center">
                  <h6>{{item.startTimeDisplay}}</h6>
                  <hr>
                  <h6>{{item.endTimeDisplay}}</h6>
                  <hr>
                  <h6>{{item.title}}</h6>
                  <hr>
                </ion-col>
                <ion-col size="12">
                  <ion-button class="m-2" [ngClass]="[ 'calendar-grid-container-delete-calendar-event-' + i]"
                    shape="round" (click)="deleteItem(i)" color="secondary">
                    Delete
                  </ion-button>
                </ion-col>
              </ion-row>

              <ion-card-header>

              </ion-card-header>

              <ion-grid style=" display: none;" class="calendar-grid-container backdrop-filter"
                [ngClass]="[ 'calendar-grid-container-' + i]">


                <!-- Start Date -->
                <ion-row class="calendar-update-row mar-top-10">
                  <ion-col class="calendar-update-row-col">
                    <ion-row>
                      <ion-col size="9">
                        <ion-label>Start Date</ion-label>
                        <ion-label>{{item.startTimeDisplay}}</ion-label>
                      </ion-col>
                      <ion-col size="3">

                        <ion-button shape="round" (click)="toggleCalendar('start')" color="light" expand="full">
                          Edit
                        </ion-button>

                      </ion-col>
                    </ion-row>

                    <ion-datetime *ngIf="showStartCalendar" displayFormat="YYYY-MM-DDTHH:mm:ss"
                      [(ngModel)]="item.startTime"></ion-datetime>
                  </ion-col>
                </ion-row>

                <!-- End Date -->
                <ion-row class="calendar-update-row mar-top-10">
                  <ion-col class="calendar-update-row-col">
                    <ion-row>
                      <ion-col size="9">
                        <ion-label>End Date</ion-label>
                        <ion-label>{{item.endTimeDisplay}}</ion-label>
                      </ion-col>
                      <ion-col size="3">

                        <ion-button shape="round" (click)="toggleCalendar('end')" color="light" expand="full">
                          Edit
                        </ion-button>

                      </ion-col>
                    </ion-row>
                    <ion-datetime color="dark" *ngIf="showEndCalendar" displayFormat="YYYY-MM-DDTHH:mm:ss"
                      [(ngModel)]="item.endTime"></ion-datetime>
                  </ion-col>
                </ion-row>
                <!-- Title -->
                <ion-row class="mar-top-10">
                  <ion-col style="border-bottom: 1px solid white;">
                    <ion-label>Description</ion-label>

                    <ion-input color="light" type=" text" placeholder="{{item.title}}" clearInput
                      [(ngModel)]="item.title"></ion-input>
                  </ion-col>
                </ion-row>
                <!-- All Day -->
                <ion-row class="mar-top-10">
                  <ion-col>

                    <ion-label>All Day Event</ion-label>
                    <ion-toggle class="mar-top-10" color="secondary" (ionChange)="toggleAllDay($event, i)"
                      checked="{{item.allDay == 1 ? true : false}}" value="{{item.allDay}}">Default
                      Toggle</ion-toggle>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col>
                    <ion-button (click)="saveChanges(i)" shape="round" color="light" expand="full">
                      Save Changes
                    </ion-button>
                  </ion-col>
                </ion-row>
                <!-- Delete Button -->
                <ion-row>
                  <ion-col>
                    <ion-button (click)="deleteItem(i)" shape="round" color="secondary"
                      expand="full">Delete</ion-button>
                  </ion-col>
                </ion-row>

              </ion-grid>
            </ion-card>

          </ion-item>

        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>