export class SlideImage {
  image: string;
  title: string;
}
export class SlideImageSingle {
  image: string;
  thumbImage: string;
  title: string;
}
export class ImageList {
  image: string;
  imageTypeId: number;
  imagesId: number;
  statusId: number;
  xApiImageId: string;
}
export class ImageSource{
  src: string;
}
export class GreenImageList {
  holeNumber: number;
  image: ImageList;
}
