<ion-content class="tournament">

  <ion-grid>
    <ion-row>


      <ion-row class="w-100">
        <ion-col style="padding:15px 15px 15px 15px" size="2">
          <ion-icon class="tournment-arrow-outline" *ngIf="!dateAsc" (click)="sortDesc(true)"
            name="arrow-up-outline"></ion-icon>
          <ion-icon class="tournment-arrow-outline" *ngIf="dateAsc" (click)="sortDesc(false)"
            name="arrow-down-outline"></ion-icon>
        </ion-col>
        <ion-col size="10">
          <ion-searchbar class="tournament-search-bar" placeholder="Search..."
            (ionInput)="searchTournaments($event.target.value)" animated="true">
          </ion-searchbar>

        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" *ngIf="tournaments.length < 1">
          <ion-item *ngFor="let p of loadingTournaments | filter:filterTerm">
            <ion-card>
              <ion-card-header>
                <ion-card-title>
                  <app-loading-spinner [dynamicHeight]="'50px'"></app-loading-spinner>
                </ion-card-title>
                <ion-card-subtitle>
                  <app-loading-spinner [dynamicHeight]="'25px'"></app-loading-spinner>
                </ion-card-subtitle>
                <ion-card-subtitle>
                  <app-loading-spinner [dynamicHeight]="'25px'"></app-loading-spinner>
                </ion-card-subtitle>
                <ion-card-subtitle>
                  <app-loading-spinner [dynamicHeight]="'25px'"></app-loading-spinner>
                </ion-card-subtitle>
              </ion-card-header>
            </ion-card>
          </ion-item>

        </ion-col>
        <ion-col class="tournament-cards-container " size="12" *ngIf="tournaments.length > 0">
          <ion-row class="mar-bottom-footer-100">

            <ion-card *ngFor="let p of tournaments; let i = index">
              <ion-card-header>
                <ion-card-title>{{p.name}}

                </ion-card-title>
                <ion-card-subtitle>
                  <p>Start Date - {{p.startDate}}</p>
                  <p>End Date - {{p.endDate}}</p>
                  <p *ngIf="p.description.indexOf('https') === -1"> {{p.description}}
                  </p>
                  <p> Type - {{p.tournmentType }}
                  </p>
                </ion-card-subtitle>
              </ion-card-header>
              <ion-card-content *ngIf="p.description.indexOf('https') !== -1">
                <a href="{{p.description}}" target="_blank" style="text-decoration:none;">
                  <ion-button shape="round" class="footer-button" color="secondary" type="submit" expand="block">
                    <ion-icon name="globe-outline"></ion-icon> Website
                  </ion-button>
                </a>

              </ion-card-content>

            </ion-card>
          </ion-row>

        </ion-col>
      </ion-row>
    </ion-row>

  </ion-grid>

</ion-content>