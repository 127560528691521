<app-loading-spinner class="w-100 p-2" *ngIf="holeGreenImages?.length < 1" [dynamicHeight]="'200px'"
  [dynamicWidth]="'90%'"></app-loading-spinner>


<swiper *ngIf="holeGreenImages?.length > 1" (swiper)="onSwiper($event)">
  <ng-template swiperSlide *ngFor="let hole of holeGreenImages">
      <div style="float:right">
        Tap to zoom
      </div>
      <ion-row>
        <img src="{{hole?.image?.image || hole.image}}" tappable (click)="openPreview(hole)" />
      </ion-row>
      <ion-row *ngIf="type != 'gps'">
        <ion-col class="user-stats-wrapper" size="12">
          <h3 class="stat-name">Hole # {{hole.holeNumber}} 
          </h3>
        </ion-col>
      </ion-row>
  </ng-template>
</swiper>