import { Component, OnInit } from '@angular/core';
import { TournamentService } from '../../services/tournament/tournament.service';
import { Tournaments } from '../../models/Tournaments';
import moment from 'moment';
import { AppService } from '../../services/state/app/app.service';
import { App } from '../../models/User';

@Component({
  selector: 'app-tournaments',
  templateUrl: './tournaments.component.html',
  styleUrls: ['./tournaments.component.scss'],
})
export class TournamentsComponent implements OnInit {
  tournamentsOriginal: Array<Tournaments> = [];
  tournaments: Array<Tournaments> = [];
  loadingTournaments: Array<Number> = [0, 1, 2];
  filterTerm: string;
  dateDesc: boolean;
  dateAsc: boolean = true;
  constructor(
    private tournamentService: TournamentService,
    private appService: AppService
  ) { }

  async ngOnInit() {
    const app = await this.appService.getCapacitorStorage('app');
    if (app != null)
      this.getTournaments(app);
  }
  searchTournaments(val: string) {
    const tourneys = this.tournaments.filter((w) => {
      if (w.name.toLowerCase().includes(val.toLowerCase())) {
        return w;
      }
    });
    if (tourneys.length < 1 || !val) {
      return (this.tournaments = this.tournamentsOriginal);
    }
    this.tournaments = tourneys;
  }
  getTournaments(appData: App) {
    this.tournamentService.getTournament().then((data) => {
      const refTournamentTypes = appData.refTournamentType;

      this.tournaments = data;
      this.tournaments.map((e) => {
        e.startDate = moment(e.startDate).format('MM/DD/YYYY');
        e.endDate = e.endDate != null ? moment(e.endDate).format('MM/DD/YYYY') : e.startDate;
        e.tournmentType = refTournamentTypes.filter(
          (w) => w.tournamentTypeId == e.refTournamentTypeId
        )[0].name;
      });
      this.tournaments.sort((a, b) => {
        return moment(a.startDate).diff(b.startDate)
      });
      this.tournamentsOriginal = this.tournaments;
    });
  }
  sortDesc(val: boolean) {
    val ? this.dateAsc = true : this.dateAsc = false;
    this.tournaments.sort((a, b) => {
      return moment(val ? a.startDate : b.startDate).diff(val ? b.startDate : a.startDate)
    });
  }
}
