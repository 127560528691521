import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GreenImageList, ImageList } from '../../models/Images';
import Swiper, { SwiperOptions } from 'swiper';
import SwiperCore, { Zoom } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
SwiperCore.use([Zoom]);

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent implements OnInit {
  @ViewChild('swiper') swiper: SwiperComponent
  @Input() imgGreens: Array<any>;
  @Input() imgGreenSelected: GreenImageList;


  config: SwiperOptions = {
    zoom: true,
  };
  constructor(private modalCtrl: ModalController) {


  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    const slide = this.imgGreens.findIndex(w => w.image.xApiImageId === this.imgGreenSelected.image.xApiImageId)
    var swiper = new Swiper('.image-swiper');
    swiper.slideTo(slide)
  }
  close() {
    this.modalCtrl.dismiss();
  }
  zoom(val: boolean) {
    const zoom = this.swiper.swiperRef.zoom;
    val ? zoom.in() : zoom.out();

  }
}
