import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ShellModule } from '../../shell/shell.module';


import { ImageSliderComponent } from './image-slider/image-slider.component';

import { SwiperModule } from 'swiper/angular';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ShellModule,
        IonicModule,
        SwiperModule,
    ],
    declarations: [
        ImageSliderComponent
    ],
    exports: [
        ShellModule,
        ImageSliderComponent
    ],
})
export class ComponentsWebModule { }
