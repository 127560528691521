import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClubAccount } from '../../../models/ClubDbModel';
import { Preferences } from '@capacitor/Preferences';

import { App } from '../../../models/User';
import { StorageService } from '../storage.service';
import { resolve } from 'dns';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  appState: any = null;
  storageService: StorageService;
  constructor(storageService: StorageService) {
    this.storageService = storageService;
  }
  public setAppState(data) {
    this.appState = data;
  }
  public getAppState() {
    return this.appState;
  }
  public async clearStorage(key: string){
    this.storageService.clear(key);
  }
  async setCapacitorStorage(key: string, val: any) {
    this.storageService.set(key, val);
  }
  async getCapacitorStorage(key: string) {
    const store = await this.storageService.get(key);
    if (store)
      return store;
  }
}
