<ion-header class="mar-top-10">
  <ion-row>
    <ion-col size="10">

    </ion-col>
    <ion-col size="2" class="ion-text-center mar-top-10">
      <ion-button (click)="close()" fill="clear" color="light">
        <ion-icon name="close" slot="start"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>

</ion-header>
<swiper class="image-swiper" [config]="config" #swiper>
  <ng-template swiperSlide *ngFor="let img of imgGreens">
    <div class="swiper-zoom-container">
      <img src="{{img.image.image || img.image}}">
    </div>
  </ng-template>
</swiper>
<ion-footer>
  <ion-row>
    <ion-col size="6" class="ion-text-center">
      <ion-button (click)="zoom(false)" fill="clear" color="light">
        <ion-icon name="remove" slot="start"></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col size="6" class="ion-text-center">
      <ion-button (click)="zoom(true)" fill="clear" color="light">
        <ion-icon name="add" slot="start"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>