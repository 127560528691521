import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../environments/environment.prod';
const routes: Routes = [
  {
    path: '',
    redirectTo: environment.web ? '/home' : '/app/login',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    redirectTo: 'app/login',
    pathMatch: 'full',
  },
  {
    path: 'app/login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'auth/signup',
    loadChildren: () =>
      import('./signup/signup.module').then((m) => m.SignupPageModule),
  },
  {
    path: 'auth/forgot-password',
    loadChildren: () =>
      import('./forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'forms-and-validations',
    loadChildren: () =>
      import('./forms/validations/forms-validations.module').then(
        (m) => m.FormsValidationsPageModule
      ),
  },
  {
    path: 'forms-filters',
    loadChildren: () =>
      import('./forms/filters/forms-filters.module').then(
        (m) => m.FormsFiltersPageModule
      ),
  },
  {
    path: 'page-not-found',
    loadChildren: () =>
      import('./page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundModule
      ),
  },
  {
    path: 'app/facebook',
    loadChildren: () =>
      import('./facebook/facebook.module').then((m) => m.FacebookPageModule),
  },
  {
    path: 'app/club/history',
    loadChildren: () =>
      import('./history/history.module').then((m) => m.HistoryPageModule),
  },
  {
    path: 'app/golf/tournaments',
    loadChildren: () =>
      import('./golf/tournaments/tournaments.module').then(
        (m) => m.TournamentsPageModule
      ),
  },
  {
    path: 'app/golf/reciprocals',
    loadChildren: () =>
      import('./golf/reciprocals/reciprocals.module').then(
        (m) => m.ReciprocalsPageModule
      ),
  },

  {
    path: 'app/notifications',
    loadChildren: () =>
      import('./news/news.module').then((m) => m.NewsPageModule),
  },
  {
    path: 'app/club/contact',
    loadChildren: () =>
      import('./contact/contact.module').then((m) => m.ContactPageModule),
  },
  {
    path: 'app/admin',
    loadChildren: () =>
      import('./admin/admin/admin.module').then((m) => m.AdminPageModule),
  },
  {
    path: 'app/news',
    loadChildren: () =>
      import('./social/social/social.module').then((m) => m.SocialPageModule),
  },
  {
    path: 'app/password/reset',
    loadChildren: () =>
      import('./password-reset/password-reset.module').then(
        (m) => m.PasswordResetModule
      ),
  },
  {
    path: 'app/password/change/:token1/:token2',
    loadChildren: () =>
      import('./password-change/password-change.module').then(
        (m) => m.PasswordChangeModule
      ),
  },
  {
    path: 'app/images/greens',
    loadChildren: () => import('./green-images/green-images/green-images.module').then(m => m.GreenImagesPageModule)
  },
  {
    path: 'app/privacy/policy',
    loadChildren: () => import('./privacy/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'app/account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountPageModule)
  },
  {
    path: 'app/calendar',
    loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarPageModule)
  },

  {
    path: 'home',
    loadChildren: () => import('./_web/home/home.module').then(m => m.HomePageModule),
    data: { preload: true }

  },
  {
    path: 'membership',
    loadChildren: () => import('./_web/membership/membership.module').then(m => m.MembershipPageModule),
    data: { preload: true }

  },
  {
    path: 'course',
    loadChildren: () => import('./_web/course/course.module').then(m => m.CoursePageModule),
    data: { preload: true }

  },
  {
    path: 'tennis',
    loadChildren: () => import('./_web/tennis/tennis.module').then(m => m.TennisPageModule),
    data: { preload: true }

  },
  {
    path: 'pool',
    loadChildren: () => import('./_web/pool/pool.module').then(m => m.PoolPageModule),
    data: { preload: true }
  },
  {
    path: 'contact',
    loadChildren: () => import('./_web/contact/contact.module').then(m => m.ContactPageModule)
  },



];
routes.push({
  path: '**',
  redirectTo: 'home',
});
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
