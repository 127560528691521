import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ShellModule } from '../shell/shell.module';

import { CheckboxWrapperComponent } from './checkbox-wrapper/checkbox-wrapper.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { CounterInputComponent } from './counter-input/counter-input.component';
import { RatingInputComponent } from './rating-input/rating-input.component';
import { ImageSliderComponent } from './image-slider/image-slider.component';
import { WeatherRadarComponent } from './weather-radar/weather-radar.component';
import { ClubStatsComponent } from './club-stats/club-stats.component';
import { FacebookComponent } from './facebook/facebook.component';
import { TournamentsComponent } from './tournaments/tournaments.component';
import { ImagesListComponent } from './images-list/images-list/images-list.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner/loading-spinner.component';
import { HeaderComponent } from './header/header/header.component';
import { FooterComponent } from './footer/footer/footer.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { GreenImagesComponent } from './green-images/green-images/green-images.component';
import { SwiperModule } from 'swiper/angular';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { ImageComponent } from './image/image.component';
import { NotificationsComponent } from './notifications/notifications/notifications.component';
import { HeaderWebComponent } from './header-web/header-web.component';
import { RouterModule } from '@angular/router';
import { ImageModalGeneric } from './image-modal-generic/image-modal-generic.component';
import { NgCalendarModule } from 'ionic6-calendar';
import { CalendarComponent } from './calendar/calendar/calendar.component';
import { CalendarUpdateComponent } from './calendar-update/calendar-update.component';
import { ToastService } from '../services/toast/toast.service';
import { ContactComponent } from './contact/contact.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ShellModule,
    IonicModule,
    Ng2SearchPipeModule,
    SwiperModule,
    RouterModule,
    NgCalendarModule,
  ],
  declarations: [
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
    CountdownTimerComponent,
    CounterInputComponent,
    RatingInputComponent,
    ImageSliderComponent,
    WeatherRadarComponent,
    ClubStatsComponent,
    FacebookComponent,
    TournamentsComponent,
    ImagesListComponent,
    LoadingSpinnerComponent,
    HeaderComponent,
    FooterComponent,
    GreenImagesComponent,
    ImageModalComponent,
    ImageComponent,
    NotificationsComponent,
    HeaderWebComponent,
    ImageModalGeneric,
    CalendarComponent,
    CalendarUpdateComponent,
    ContactComponent,
  ],
  exports: [
    ShellModule,
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
    CountdownTimerComponent,
    CounterInputComponent,
    RatingInputComponent,
    ImageSliderComponent,
    WeatherRadarComponent,
    ClubStatsComponent,
    FacebookComponent,
    TournamentsComponent,
    ImagesListComponent,
    LoadingSpinnerComponent,
    HeaderComponent,
    FooterComponent,
    GreenImagesComponent,
    ImageModalComponent,
    ImageComponent,
    NotificationsComponent,
    HeaderWebComponent,
    ImageModalGeneric,
    CalendarComponent,
    CalendarUpdateComponent,
    ContactComponent,
  ],
})
export class ComponentsModule { }
