import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Tournaments } from '../../models/Tournaments';
import { BoardAndMembers } from '../../models/Board';
import { Constants } from '../../models/_constants';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class TournamentService {
  api: string;
  postId: any;

  constructor(
    private http: HttpClient,
    public constants: Constants,
    private utilsService: UtilsService
  ) {
    this.api = this.constants.apiTournaments;
  }
  public async getTournament() {
    const header = await this.utilsService.getApiHeaders();
    const data = await this.http.get<Tournaments[]>(this.api, { headers: header, }).toPromise();
    if (data != null)
      return data;
  }
}
