<!-- <ion-header>
  <ion-toolbar>
    <ion-buttons  *ngIf="showMenu" slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>{{title}}</ion-title>

  </ion-toolbar>
</ion-header> -->
<ion-header class="header ion-no-border">
  <ion-toolbar>
    <ion-buttons *ngIf="showMenu" slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title class="titleHeader">
      <div class="header-web-title" *ngIf="isWeb">{{title}}</div>
      <h5 *ngIf="!isWeb">
        {{title}}
      </h5>
    </ion-title>
    <ion-buttons *ngIf="isWeb" slot="primary" class="m-2">
      <ion-button>
        <h4> Membership</h4>
      </ion-button>
    </ion-buttons>
    <ion-buttons *ngIf="isWeb" slot="primary" class="m-2">
      <ion-button>
        <h4> Course Tour</h4>
      </ion-button>
    </ion-buttons>
    <ion-buttons *ngIf="isWeb" slot="primary" class="m-2">
      <ion-button>
        <h4> Amenities</h4>
      </ion-button>
    </ion-buttons>
    <ion-buttons *ngIf="isWeb" slot="primary" class="m-2">
      <ion-button>
        <h4> Contact</h4>
      </ion-button>
    </ion-buttons>
    <ion-buttons *ngIf="isWeb" slot="primary" class="m-2">
      <ion-button>
        <h4> Jobs Openings</h4>
      </ion-button>
    </ion-buttons>
    <ion-buttons [attr.slot]="!showMenu ? 'start' : 'end'">
      <img style="margin:5px;background-color: white;border-radius: 30px;padding:5px 5px 5px 5px"
        src="https://clubmobile.blob.core.windows.net/images/icon-transparent.png" alt="your image"
        width="{{isMobile ? '30' : '60'}}" height="{{isMobile ? '30' : '60'}}">
    </ion-buttons>
  </ion-toolbar>
</ion-header>