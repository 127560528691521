<ion-row class="user-stats-section weather-section">
    <ion-col size="12">
        <ion-item-divider sticky="true">
            <ion-label>Weather</ion-label>
        </ion-item-divider>
    </ion-col>

    <ion-row class="p-1">

        <div class="weather-iframe-container">
            <iframe width="650" height="450"
                src="https://www.rainviewer.com/map.html?loc=34.2799,-80.6396,8&oFa=0&oC=1&oU=0&oCS=1&oF=0&oAP=1&c=3&o=90&lm=1&layer=radar&sm=1&sn=1&hu=0"
                frameborder="0" style="padding-left: 20px;padding-right: 20px;"></iframe>

            <!-- <iframe
                src="https://embed.windy.com/embed2.html?lat=34&lon=-80.907&width=650&height=450&zoom=6&level=surface&overlay=rain&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1"
                frameborder="0"></iframe> -->
        </div>
    </ion-row>
    <ion-col>

    </ion-col>

</ion-row>