<ion-content class="image-list">
    <ion-row class="image-list-row">
        <ion-col size="12" *ngIf="imageLoading">
            <app-loading-spinner [dynamicHeight]="'110px'"></app-loading-spinner>
        </ion-col>
        <ion-col *ngIf="!imageLoading" class="image-list-col" size="12">
            <div *ngFor="let p of imageList; let i = index">
                <img class="image-list-col-image" src="{{p.image}}" (load)="this.imageLoading = false;" />
            </div>
        </ion-col>
    </ion-row>
    <div>

    </div>
</ion-content>