<ion-row>
  <ion-col *ngIf="!limit" size="12">
    <ion-searchbar class="tournament-search-bar" placeholder="Search by text or date..."
      (ionInput)="searchNotifications($event.target.value)" animated="true">
    </ion-searchbar>
  </ion-col>
  <ion-col size="12" *ngIf="showHeader">
    <ion-item-divider sticky="true">
      <ion-label>Notifications</ion-label>
    </ion-item-divider>
  </ion-col>
  <ion-col size="12" *ngIf="notifications?.notifications.length < 1">
    <ion-item *ngFor="let p of loadingNotifications">
      <ion-card class="w-100">
        <ion-card-header>
          <ion-card-title>
            <app-loading-spinner [dynamicHeight]="'50px'"></app-loading-spinner>
          </ion-card-title>
          <ion-card-subtitle>
            <app-loading-spinner [dynamicHeight]="'25px'"></app-loading-spinner>
          </ion-card-subtitle>
        </ion-card-header>
      </ion-card>
    </ion-item>

  </ion-col>
</ion-row>
<ion-row [ngClass]="{'notification-cards-container-max-500': showSmallList}" class="notification-cards-container">
  <ion-col *ngIf="notifications?.notifications.length > 0" [ngClass]="{'mar-bottom-footer-100': !limit}">
    <ion-card *ngFor="let p of notifications?.notifications; let i = index">
      <ion-item>
        <ion-label>
          <img *ngIf="p.large_icon != null" class="notification-large-icon" width="20" height="20"
            src="{{p.large_icon}}">
          <h2 class="card-title mar-bottom-footer-10 white-space-normal">{{p.headings.en}}
          </h2>
          <h3 class="mar-bottom-footer-10 white-space-normal"> {{p.contents.en}}</h3>
          <p *ngIf="p.global_image" style="float: right;">Tap to zoom</p>
          <p *ngIf="p.global_image" class="w-100">
            <img class="notifications-global-image" src="{{p.global_image}}" (click)="openPreview($event)" />
          </p>
          <p style="float: right;">
            {{p.queued_at}}
          </p>
        </ion-label>
      </ion-item>
    </ion-card>
    <ion-row *ngIf="limit" class="centered">

      <ion-button [routerLink]="['/app/notifications']" size="small" shape="round" class="get-yardage-button">See
        More
      </ion-button>
    </ion-row>
  </ion-col>
</ion-row>

<style>
  .user-stats-wrapper {
    text-align: center;
    margin-bottom: 25px;
    margin-top: 25px;
  }

  .stat-name-container {
    margin: 20px
  }
</style>