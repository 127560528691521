import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Constants {

  api: string = 'https://clubmobileapi.azurewebsites.net/api';
  //api: string = 'https://localhost:44334/api';
  apiOneSignal: string = 'https://onesignal.com/api/v1'
  apiMembership: string = this.api + '/Membership';
  apiLogin: string = this.api + '/login/authenticate';
  apiCreateUser: string = this.api + '/Login/CreateUser';
  apiPasswordReset: string = this.api + '/Password/email';
  apiPasswordChange: string = this.api + '/Password/change';
  apiAccount: string = this.api + '/Account';
  apiNews: string = this.api + '/News';
  apiImages: string = this.api + '/Image';
  apiTournaments: string = this.api + '/Tournament';
  apiCompanyContact: string = this.api + '/CompanyContact';
  apiCoordinates: string = this.api + '/Coordinates?courseId=1&holeId=';
  apiCourseCoordinates: string = this.api + '/CourseCoordinates/1';
  apiCalendar: string = this.api + '/Calendar';
  apiReciprocals: string = this.api + '/Reciprocals';
  apiBoard: string = this.api + '/Board';
  apiGreensImages: string = this.api + '/Greens/images/1';
  apiOneSignalNotifications: string = this.api + '/OneSignal'

}
