import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contact } from '../../models/Contact';
import { Constants } from '../../models/_constants';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  api: string;
  postId: any;

  constructor(
    private http: HttpClient,
    public constants: Constants,
    private utilsService: UtilsService
  ) {
    this.api = this.constants.apiCompanyContact;
  }

  public async getCompanyContact() {
    const header = await this.utilsService.getApiHeaders();
    const data = await this.http.get<Contact[]>(this.api, { headers: header, }).toPromise();
    return data;
  }
}
