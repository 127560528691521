import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    if (this._storage == null) {
      this.setAndCreateStorage();
    }

  }
  private async setAndCreateStorage() {
    const storage = await this.storage.create();
    if (storage != null) {
      this._storage = storage;
      return storage;
    }

  }
  // Create and expose methods that users of this service can
  // call, for example:
  public set(key: string, value: any) {
    this._storage?.set(key, value);
  }
  public async get(key: string) {
    if (this._storage == null) {
      const creation = await this.setAndCreateStorage();
      if (creation != null) {
        const name = await this._storage.get(key);
        return name;
      }
    } else {
      const name = await this._storage.get(key);
      return name;
    }
  }
  public async remove(key: string) {
    await this._storage.remove(key);
  }
  public async clear(key: string) {
    await this._storage.clear();
  }
  public async keys(key: string) {
    await this._storage.keys();
  }
}